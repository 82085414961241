table.admin-actions {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ddd;
    margin-bottom: 20px;
}

table.admin-actions th, table.admin-actions td {
    text-align: left;
    padding: 10px;
    border: black 1px solid;
}

table.admin-actions tr.colored {
    background-color: #97e09a;
}

table.permissions {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ddd;
    margin-bottom: 20px;
}

table.permissions th, table.permissions td {
    text-align: left;
    padding: 10px;
    border: black 1px solid;
}

table.permissions tr.even {
    background-color: #c2c2c2;
}

table.permissions tr.odd {
    background-color: #e0e0e0;
}

.sticky-mass-action > .Polaris-Card {
    padding: 10px;
}

.sticky-mass-action { margin-left: 5px; margin-top: 5px; margin-bottom: 5px;}

.sticky-mass-action-fields > * { margin-top: 5px; margin-bottom: 5px;}

.sticky-mass-action-fields-title { margin-bottom: 3px; font-weight: 600;}

.sticky-buttons-wrapper { margin-left: 5px; margin-bottom: 5px;}


.sticky-mass-action-upload-wrapper {
    border: 1px solid #e0e0e0;
    padding: 10px;
    border-radius: 3px;
    width: fit-content;
}
